/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { StaticImage } from "gatsby-plugin-image";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";
import Banner from "../../static/imgs/banner-img.png";

const GeneralFund = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Donation For Bhandara";
    return `<gatsby_donation donationId="${id}" btntext="${titletext}"
    btnBg=#085788 colortext = navy colorprimary = #085788
    colorsecondary = midnightblue colorbackground = white
    colormuted = #f6f6f6 colorhighlight = #efeffe titletext =
    "Donation For Bhandara" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Donation_For_Bhandara", event),
    []
  );

  return (
    <>
      <DonationLayout seoData={{ title: "Donation For Bhandara" }}>
        <Box
          sx={{
            textAlign: "center",
            minHeight: "550px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${Banner})`,
            paddingTop: "340px",
            backgroundPosition: "center center",
          }}
        >
          <Box sx={{ padding: "0px 0px 0px " }}>
            <h2
              sx={{
                color: "#085788",
                fontWeight: "600",
              }}
            >
              Donation For Bhandara
            </h2>
            <p
              sx={{
                fontSize: "18px",
                width: "70%",
                margin: "10px auto",
                paddingTop: "4px",
              }}
            >
              The Heartfulness organizations run on donations and grants, and
              are registered as charitable non-profit organizations. The events
              and celebrations of this magnitude incur donations from you all.
              Please consider donating whatever you feel to give, but there
              there is no compulsion to donate. It all depends on your means and
              willingness!
            </p>
            <Box sx={{ margin: "10px" }}>
              <PageContent
                eventCallback={eventCallback}
                pageContent={pageContent(35, "Contribute Now")}
              />
            </Box>
            <p
              sx={{
                fontSize: "18px",
                marginBottom: "1rem",
              }}
            >
              All donations are tax-exempted as eligible under section 80G of
              the the Income Tax Act, 1961
            </p>
          </Box>
        </Box>
        <Grid
          columns={[1, 1, 2, null]}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <Box
              sx={{
                padding: "40px 0 0",
                background: "#fcfcff",
                marginBottom: "20px",
              }}
            >
              <Box className="container" sx={{ margin: "0 auto" }}>
                <Box className="row">
                  <Box className="col-md-6">
                    <Box className="general-fund-image">
                      <StaticImage
                        src="../../static/imgs/group-general-fund.png"
                        alt="Logo"
                      />
                    </Box>
                  </Box>
                  <Box className="col-md-6">
                    <Box className="general-fund-info">
                      <p
                        sx={{
                          color: "#085788",
                          fontWeight: "600",
                          fontSize: "20px",
                          marginBottom: "1rem",
                        }}
                      >
                        Heartfulness Programs
                      </p>
                      <p
                        sx={{
                          fontSize: "18px",
                          marginBottom: "1rem",
                        }}
                      >
                        These programs are conducted exclusively by volunteers,
                        and the techniques are taught free of charge to
                        participants. Our dedicated volunteers, many of whom are
                        professionals, donate their time and talent and cover
                        their own expenses. Your donation will support our
                        programs in schools, universities and colleges,
                        corporates, hospitals and villages in more than 130
                        countries, as well as to any interested groups and
                        communities.
                      </p>
                      <p
                        sx={{
                          fontSize: "18px",
                          marginBottom: "1rem",
                        }}
                      >
                        Donations of any size make it possible for us to change
                        lives by teaching the Heartfulness techniques and
                        offering various initiatives to communities around the
                        world.
                      </p>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="container" sx={{ margin: "0 auto" }}>
                <Box className="row">
                  <Box
                    sx={{
                      paddingTop: "20px",
                      textAlign: "center",
                      fontSize: "25px",
                    }}
                  >
                    <p
                      sx={{
                        fontWeight: "600",
                        fontSize: "23px",
                        marginBottom: "1rem",
                      }}
                    >
                      Construction and Upkeep of the World’s Largest
                      self-sustainable Meditation Center
                    </p>
                    <p
                      sx={{
                        fontSize: "18px",
                        marginBottom: "1rem",
                      }}
                    >
                      This initiative is towards supporting and covering large
                      expenses in Kanha Shanti Vanam, the world headquarters of
                      the Heartfulness Movement near Hyderabad. These include
                      the construction of various state-of-the-art facilities,
                      accommodation and food for thousands of practitioners
                      visiting throughout the year, and maintenance of all
                      facilities.
                    </p>
                    <p
                      sx={{
                        fontSize: "18px",
                        marginBottom: "1rem",
                      }}
                    >
                      Additionally, this fund also supports the financing of
                      forestation, plantation, tissue culture labs to cultivate
                      endangered species, and water conservation efforts in
                      Kanha Shanti Vanam.
                    </p>
                  </Box>
                  <Box className="general-fund-image">
                    <StaticImage
                      src="../../static/imgs/kanha-image.png"
                      alt="Logo"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </DonationLayout>
    </>
  );
};

export default GeneralFund;
